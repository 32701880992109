const logo = "https://info-thinker.oss-cn-beijing.aliyuncs.com/logo.png"
const i1 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/i1.png"
const i2 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/i2.png"
const conflux = "https://info-thinker.oss-cn-beijing.aliyuncs.com/conflux.png"
const ethereum = "https://info-thinker.oss-cn-beijing.aliyuncs.com/ethereum.png"
const polygon = "https://info-thinker.oss-cn-beijing.aliyuncs.com/polygon.png"
const binance = "https://info-thinker.oss-cn-beijing.aliyuncs.com/binance.png"
const bsn = "https://info-thinker.oss-cn-beijing.aliyuncs.com/bsn.png"
const features1 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-1.png"
const features2 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-2.png"
const features3 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-3.png"
const features4 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-4.png"
const features5 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-5.png"
const features6 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/features-6.png"
const partner1 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/partner-1.png"
const partner2 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/partner-2.png"
const partner3 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/partner-3.png"
const partner4 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/partner-4.png"
const partner5 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/partner-5.png"
const plan1 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/plan-1.png"
const plan2 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/plan-2.png"
const plan3 = "https://info-thinker.oss-cn-beijing.aliyuncs.com/plan-3.png"

const IMAGE = {
  logo, i1, i2, conflux, ethereum, polygon, binance, bsn, features1, features2, features3, features4, features5, features6, partner1, partner2, partner3, partner4, partner5, plan1, plan2, plan3
}
export default IMAGE