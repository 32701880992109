import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Button, Tabs, Modal } from "antd"
import IMAGE from "@/assets/image"
import "./styles.less"

const IndexPage = ({ history }) => {
  const [open, setOpen] = useState(false)
  
  return (
    <div className="home-page">
      <div className="session-1">
        <div className="session-1-div">
          <div className="text-div">
            <p className="session-1-title">桥接Web3.0  助力数字化营销</p>
            <p className="session-1-content">基于PaaS模式，为企业提供基于多个区块链底层的客户关系管理能力，打通元宇宙时代的营销之路</p>
            <Button className="contact-button" onClick={() => setOpen(true)}>联系商务</Button>
          </div>
          <img src={ IMAGE.i1 } alt="" className="image" />
        </div>
      </div>
      <div className="session-2">
        <div className="session-2-div">
          <p className="session-2-title">一站式区块链接入平台</p>
          <p className="session-2-content">多链对接 跨链同步 标准接口 高效合规</p>
          <ul className="session-2-list">
            <li className="session-2-li">
              <div className="session-2-li-div">
                <img src={ IMAGE.conflux } alt="" />
                <p>Conflux</p>
              </div>
            </li>
            <li className="session-2-li">
              <div className="session-2-li-div">
                <img src={ IMAGE.ethereum } alt="" />
                <p>Ethereum</p>
              </div>
            </li>
            <li className="session-2-li">
              <div className="session-2-li-div">
                <img src={ IMAGE.polygon } alt="" />
                <p>Polygon</p>
              </div>
            </li>
            <li className="session-2-li">
              <div className="session-2-li-div">
                <img src={ IMAGE.binance } alt="" />
                <p>BinanceChain</p>
              </div>
            </li>
            <li className="session-2-li">
              <div className="session-2-li-div">
                <img src={ IMAGE.bsn } alt="" />
                <p>BSN</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="session-3">
        <div className="session-3-bg"></div>
        <div className="session-3-div">
          <p className="session-3-title">行业解决方案</p>
          <p className="session-3-content">行业解决方案</p>
          <div className="session-3-tab-div">
            <Tabs centered items={[ {
              key: "1",
              label: "文创行业",
              children: <img src={ IMAGE.plan1 } alt="" className="session-3-tab-content" />
            },
            {
              key: "2",
              label: "旅游行业",
              children: <img src={ IMAGE.plan2 } alt="" className="session-3-tab-content" />
            },
            {
              key: "3",
              label: "零售行业",
              children: <img src={ IMAGE.plan3 } alt="" className="session-3-tab-content" />
            }, ]} />
          </div>
        </div>
      </div>
      <div className="session-4">
        <p className="session-4-title">顺科云服务特点</p>
        <ul className="session-4-list">
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features1 } alt="" />
            </div>
            <p className="session-4-li-title">连接稳定</p>
            <p className="session-4-li-content">对海内外的区块链节点建立了稳定可靠的连接，确保数据能及时高效的上链</p>
          </li>
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features2 } alt="" />
            </div>
            <p className="session-4-li-title">安全可靠</p>
            <p className="session-4-li-content">多层的安全与加密手段，实现了区块链账户的托管，及账户密钥的有序管理</p>
          </li>
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features3 } alt="" />
            </div>
            <p className="session-4-li-title">使用简单</p>
            <p className="session-4-li-content">提供多种常见的网络API调用方式、直观的管理界面、简洁明了的文档</p>
          </li>
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features4 } alt="" />
            </div>
            <p className="session-4-li-title">配置灵活</p>
            <p className="session-4-li-content">拥有分布式存储、权益核销、链上数据检索和查询等配套模块可供选择</p>
          </li>
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features5 } alt="" />
            </div>
            <p className="session-4-li-title">服务专业</p>
            <p className="session-4-li-content">实时跟踪区块链数据传输效率等情况，自动选择线路并代付链上手续费</p>
          </li>
          <li className="session-4-li">
            <div className="session-4-image-div">
              <img src={ IMAGE.features6 } alt="" />
            </div>
            <p className="session-4-li-title">价格合理</p>
            <p className="session-4-li-content">零月租零固定收费，定价公开透明，自助选择服务内容，按实际使用量计费</p>
          </li>
        </ul>
      </div>
      <div className="session-5">
        <p className="session-5-title">客户与合作伙伴</p>
        <div className="session-5-list">
        {
          [IMAGE.partner1, IMAGE.partner2, IMAGE.partner3, IMAGE.partner4, IMAGE.partner5].map((item, index) => {
            return <img src={ item } alt="" key={index} />
          })
        }
        </div>
      </div>
      <Modal centered open={open} footer={null} destroyOnClose={true} closable={true} wrapClassName="contact-modal" onCancel={() => setOpen(false)}>
        <img src={ IMAGE.i2 } alt=""  />
      </Modal>
    </div>
  )
}
export default withRouter(IndexPage)