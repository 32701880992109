import React from "react"
import "./styles.less"

const IndexComponent = () => {

  return (
    <div className="foot-component">
      <div className="foot-body">顺科软件 ©️ 2023 ALL RIGHTS RESERVED. 保留所有权利<br /><span onClick={() => window.open("https://beian.miit.gov.cn/")}>粤ICP备09048501号-1</span></div>
    </div>
  )
}

export default IndexComponent