import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import IMAGE from "@/assets/image"
import "./styles.less"

const IndexComponent = () => {
  const location = useLocation()

  useEffect(() => {
    document.body.scrollIntoView()
  }, [location])


  return (
    <div className="head-component">
      <div className="head-body">
        <img src={ IMAGE.logo } alt="" className="logo" />
        <div className="buttons">
          <p onClick={() => window.open("https://docs.cloud.infothinker.com/")}>文档中心</p>
          <p onClick={() => window.open("https://console.cloud.infothinker.com/")}>控制台</p>
        </div>
      </div>
    </div>
  )
}
export default IndexComponent
