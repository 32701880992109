import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@/i18n"
import { Head, Foot } from "@/component"
import { Home, NotFound } from "@/page"
import "@/until/init.client"
import "./App.less"

const App = () => {

  return (
    <Router>
      <Head />
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
      <Foot />
    </Router>
  )
}

export default App